
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, menuController, IonButtons, onIonViewWillEnter } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";
import { Calendar } from "v-calendar";
import { openToast } from "@/services/toast";
import { dateFormat, timeFormat } from "@/services/utils";

import apiAppuntamenti from "@/services/appuntamenti_squadre";
import apiCalendario from "@/services/calendario";


import EventDetail from "@/components/calendario/EventDetail.vue";

export default {
    name: "Calendario2",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        Calendar,
    },
    setup() {
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const eventsToShow = ref([]);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        const events = ref([]);


        /**
         * ! Open event detail page
         */
         async function openDetailModal(event) {
            let currentEventId = event.events_id;

            const modal = await modalController.create({
                component: EventDetail,
                componentProps: {
                    data: event,
                },
            });

            modal.onDidDismiss().then((detail) => {
                loadData();
                //console.log(detail);
                if (detail.data) {
                    const deleted = detail.data;

                    if (deleted && currentEventId) {
                        //Filtro array event togliendo quello eliminato
                        events.value = events.value.filter((event) => event.events_id != currentEventId);
                        //Filtro array eventi visualizzati togliendo quello eliminato
                        eventsToShow.value = eventsToShow.value.filter((event) => event.events_id != currentEventId);

                        openToast("Evento eliminato con successo", "toast_success");
                    } else {
                        openToast("Si è verificato un errore durante l'eliminazione dell'evento", "toast_danger");
                    }
                }
                // Reset current event
                currentEventId = null;
            });

            return modal.present();
        }



        /**
         * ! Set calendar data
         */
        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const setAttributes = computed(() => {
            return events.value.map((event) => ({
                key: `event.${event.events_id}`,
                highlight: {
                    color: moment(event.events_date_start).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD") ? "green" : "blue",
                    fillMode: "solid",
                },
                dates: event.events_date_start,
                customData: event,
            }));
        });

        /**
         * ! Mostra solo gli appuntamenti del giorno selezioanto
         */
        const dayClicked = (day) => {
            selectedDay.value = day;
            eventsToShow.value = [];
            eventsToShow.value = day.attributes.length == 0 ? [] : day.attributes.map((appuntamento) => appuntamento.customData);
        };


        /**
         * 
         * ! Ad ogni cambio mese richiedo i rapportini di quello corrente
         * 
         */
        async function loadData(startDate: string = moment().startOf('month').format('YYYY-MM-DD'), endDate: string = moment().endOf('month').format('YYYY-MM-DD')) {
            events.value = [];
            eventsToShow.value = [];
            //console.log(startDate, endDate);
            try {                
                const res = await apiCalendario.getEvents(userID, startDate, endDate);

                if(res.status === 200 && res.data.status === 0) {
                    events.value = res.data.data;
                    //Popolo array degli eventi da mostrare con quelli di oggi per vederli subito
                    const todayApp = res.data.data.filter((el) => 
                        moment(el.events_date_start).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                    );
                    eventsToShow.value = todayApp;
                } else {
                    openToast("Si è verificato un errore durante la richiesta degli eventi", "toast_danger");
                }
            } catch (error) {
                events.value = [];
                openToast("Si è verificato un errore durante la richiesta degli eventi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        const loadCurrentMonthData = (page) => {
            const currentMonth = page.month;
            const currentYear = page.year;

            const momentStartDate = moment([currentYear, currentMonth - 1]);
            const momentEndDate = moment(momentStartDate).endOf("month");
            const formatStart = momentStartDate.format("YYYY-MM-DD");
            const formatEnd = momentEndDate.format("YYYY-MM-DD");

            loadData(formatStart, formatEnd).then(() => {
                const isCurrentMonth = moment().month() + 1 === currentMonth && moment().year() === currentYear;

                if (isCurrentMonth) {
                    // Se la data selezionata non è nel mese corrente, imposta quella attuale
                    if (!selectedDay.value || !moment(selectedDay.value.id).isBetween(formatStart, formatEnd, null, '[]')) {
                        selectedDay.value = { id: moment().format("YYYY-MM-DD") };
                    }
                    // Mostra eventi del giorno selezionato
                    eventsToShow.value = events.value.filter(event =>
                        moment(event.events_date_start).format("YYYY-MM-DD") === selectedDay.value.id
                    );
                }
            });
        };

        onMounted(() => {
            //GET APPUNTAMENTI DATA
            //loadAppuntamenti();
        });

        return {
            loading,
            add,
            refresh,
            openDetailModal,
            menu,
            openMenu,
            //Appuntamenti
            dateFormat,
            timeFormat,
            Calendar,
            date,
            selectedDay,
            dayClicked,
            setAttributes,
            eventsToShow,
            loadCurrentMonthData,
            loadData
        };
    },
};
